import React, {useState, useEffect} from 'react'
import Cookies from 'js-cookie';
import api from "src/services/api";
import legacyFetcher from "src/services/legacyEndpoints";

const useCsrfToken = () => {
    const [csrfToken, setCsrfToken] = useState()

    useEffect(() => {
        Cookies.set("csrftoken", csrfToken)
        api.defaults.headers.common["X-CSRFToken"] = csrfToken
        legacyFetcher.defaults.headers.common["X-CSRFToken"] = csrfToken
    }, [csrfToken])

    return {
        csrfToken, 
        setCsrfToken
    }
}

export default useCsrfToken