import { createStore, combineReducers } from 'redux';

const authInitialState = {
  customerToken: localStorage.getItem('customer_token') || "",
  customerName: localStorage.getItem('customer_name') || "",
  user: localStorage.getItem('user') || {},
}

const authReducer = (state = authInitialState, { type, ...rest }) => {
  switch (type) {
    case 'set':
      return {...state, ...rest };
    case 'customerLogin':
      localStorage.setItem("customer_token",rest.customerToken)
      localStorage.setItem("customer_name",rest.customerName)
      return {...state, customerToken: rest.customerToken, customerName: rest.customerName};
    case 'customerLogout':
      localStorage.removeItem("customer_token")
      localStorage.removeItem("customer_name")
      return {...state, customerToken: ""};
    case 'userLogin':
      localStorage.setItem("user",rest.user)
      return {...state, user: rest.user};
    case 'userLogout':
      localStorage.removeItem("user")
      return {...state, user: {}};
    default:
      return state
  }
}

const themeInitialState = {
  sidebarShow: 'responsive'
}

const themeReducer = (state = themeInitialState, { type, ...rest }) => {
  switch (type) {
    case 'set':
      return {...state, ...rest };
    default:
      return state
  }
}

const reducers = {
  theme: themeReducer,
  auth: authReducer,
}

const reducer = combineReducers(reducers)
const store = createStore(reducer)
export default store
