import React, {useEffect, useState} from 'react';
import { HashRouter, Route, Switch, Redirect } from 'react-router-dom';
import { useSelector} from 'react-redux'
// import 'react-redux-toastr/lib/css/react-redux-toastr.min.css';
import './scss/style.scss';
import './I18n';
import customerAPI from './services/customerapi';
import api from './services/api';
import { ToastContainer } from 'react-toastify';
import { FormProvider } from 'src/context/form'
import { useDispatch } from 'react-redux';
import useCsrfToken from './hooks/useCsrfToken';
import { COMPANY_DISPLAY_NAME } from './utils/constants';

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

// Containers
const TheLayout = React.lazy(() => import('./containers/TheLayout'));

// Pages
const CustomerLogin = React.lazy(() => import('./views/customer-area/login/Login'));
const Login = React.lazy(() => import('./views/auth/Login'));
// const Register = React.lazy(() => import('./views/pages/register/Register'));
const Page404 = React.lazy(() => import('./views/pages/page404/Page404'));
const Page500 = React.lazy(() => import('./views/pages/page500/Page500'));

function App() {
  const customerToken = useSelector(state => state.auth.customerToken)
  const [isAuthenticated, setIsAuthenticated] = useState(false)
  const [loading, setLoading] = useState(true)
  const dispatch = useDispatch()
  const {setCsrfToken} = useCsrfToken()

  if (customerToken){
    customerAPI.defaults.headers["Authorization"] = `Bearer ${customerToken}`
  }

  function PrivateRoute({ component, ...rest }) {
    api.get("/common/is_authenticated/").then(({data}) => {
      // Cookies.set("csrftoken", data.csrftoken)
      setCsrfToken(data.csrftoken)
      if (!data.is_authenticated){     
        dispatch({type: 'userLogout'})           
        // window.location.href = "/login"
      } else {
        dispatch({type: 'userLogin', user: data.user})
        setIsAuthenticated(true)
      }    
    }).catch((e) => {
      console.error(e);
      dispatch({type: 'userLogout'})
      // window.location.href = "/login"
    }).finally(() => setLoading(false))
    
    return (
      <Route
        {...rest}
        render={props =>
          !loading ? (              
            isAuthenticated ? <FormProvider>{React.createElement( component, {...props, app: "main"})}</FormProvider>
             :
              <Redirect
              to={{
                pathname: "/auth/login",
                state: {
                  from: props.location,
                },
              }}
            />
          ) : (
            <span>loading...</span>            
          )
        }
      />
    );
  }

  function CustomerRoute({ component, ...rest }) {    
    return (
      <Route
        {...rest}
        render={props =>          
          customerToken ?          
          (          
            React.createElement(component, {...props, app: "customer-area"})
          ) : (
            <Redirect
              to={{
                pathname: "/customer-area/login",
                state: {
                  from: props.location,
                },
              }}
            />
          )
        }
      />
    );
  }

  return (
    <HashRouter>
        <React.Suspense fallback={loading}>
          <ToastContainer position={"top-center"} />
          <Switch>
            <Route exact path="/customer-area/login" name="Customer Area Login" render={props => <CustomerLogin {...props}/>} />
            <Route exact path="/auth/login" name={`${COMPANY_DISPLAY_NAME} - Login`} render={props => <Login {...props}/>} />
            <Route exact path="/404" name="Page 404" render={props => <Page404 {...props}/>} />
            <Route exact path="/500" name="Page 500" render={props => <Page500 {...props}/>} />
            {/* <Route path="/" name="Home" component={TheLayout} /> */}
            <CustomerRoute path="/customer-area" name="Customer Area" component={TheLayout} />
            <PrivateRoute path="/" name="Home" component={TheLayout} />
          </Switch>
        </React.Suspense>
    </HashRouter>
  );


}

export default App;
